import styled from "styled-components";

const VideoButton = styled.button`
    margin: 0 auto;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: block;
    width: 100%;
`

export default VideoButton