import React, {Component} from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import {Row, Col, Container} from "reactstrap"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"
import tube from "images/tube.svg"
import rail from "images/rail.svg"
import bus from "images/bus.svg"
import HeadingSpecial from "components/shared/HeadingSpecial"


import {handleLinkClick} from '../../js/handleLinkClick'

const MapContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;

  @media ${media.lg} {
    margin-top: 0;
    text-align: left;
  }

  a {
    margin: 0 auto;

    @media ${media.sm} {
      margin: 0;
    }
  }
  
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-right: 5px;

    @media ${media.lg} {
    margin-top: 0 ;
      padding-right: 10px;
      width: 35px;
      height: 35px;
    }
  }
  
  
`

const Content = styled.div`
  margin: 2rem auto;
  max-width: 1260px;
  padding: 0 1rem;

  @media ${media.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mapImage {
    margin: 0 auto;
    max-width: 240px;
    @media ${media.md} {
      padding-right: 2rem;
      max-width: 500px;
    }
    @media ${media.lg} {
      padding-right: 4rem;
    }
    @media ${media.xl} {
      padding-right: 8rem;
    }
  }
`

const MapQuery = () => (
    <StaticQuery
        query={graphql`
            query {
                MapImage: file(relativePath: { eq: "HomePage/map.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 500, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                EdwardianImage: file(relativePath: { eq: "HomePage/Edwardian-Hotels-London.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 500,
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
               
            }
		`}
        render={data => (
            <Map data={data}/>
        )}
    />
)

class Map extends Component {
    render() {
        return (
            <Container fluid={true} className="no-padding-xs">
                <HeadingSpecial headingTag="h2" className="pt-4 pt-sm-5 pb-3 pb-lg-5"
                                title="Getting To The Theatre"/>
                <Container>
                    {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                    <Content>
                        <div className="mapImage">
                            <GatsbyImage image={this.props.data.MapImage.childImageSharp.gatsbyImageData}
                                         alt="Theatre Royal Drury Lane"
                                         title="Theatre Royal Drury Lane"
                                         className="img-fluid d-block"
                            />
                        </div>
                        <MapContent>
                            <p className="color--black font-weight-bold">Theatre Royal Drury Lane</p>
                            <p className="color--black">Catherine St,<br/>Covent Garden<br/>London</p>
                            <p className="color--black">WC2B 5JF</p>
                            <p className="color--black d-md-flex align-top">
                                <img src={tube} className="icon" alt="tube"/><span><strong>Tube stations:</strong> Covent Garden (0.2 miles), Holborn
                                (0.4 miles), Leicester Square (0.4 miles), Charing&nbsp;Cross (0.5 miles), Waterloo (0.8
                                miles)</span></p>
                            <p className="color--black d-md-flex align-top">
                                <img src={rail} className="icon"
                                     alt="rail"/><span><strong>Overground:</strong> Charing&nbsp;Cross (10 min walk), Waterloo
                                (20 min walk)</span></p>
                            <p className="color--black d-md-flex align-top">
                                <img src={bus} className="icon" alt="bus"/><span><strong>Car parks:</strong> Covent Garden and St Martin’s Lane</span>
                            </p>
                            <a href="https://goo.gl/maps/haYi9Ny9k3Zq8kLP7"
                               onClick={(e) => handleLinkClick(e)}
                               className="btn btn--new d-flex mx-auto mx-lg-0 text-uppercase"
                            ><span>View on Google Maps</span></a>
                        </MapContent>
                    </Content>
                    {/*</ScrollAnimation>*/}
                </Container>
            </Container>
        )
    }
}

export default MapQuery